import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { StringParam, useQueryParam } from 'use-query-params';

import * as metricActions from 'actions/revbi/metrics';
import { Header } from 'components/dashboard/Metrics/Create/Header/Header';
import { WidgetCreateOptions } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/WidgetCreateOptions';
import { WidgetPreview } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetPreview/WidgetPreview';
import {
  FUNNEL_PREDEFINED_METRICS,
  FUNNEL_PREDEFINED_TOP_METRICS,
  NEW_WIDGET_MOCK,
} from 'components/dashboard/Metrics/Create/WidgetCreate/constants';
import {
  CreationType,
  NOT_SAVED_METRIC,
  OptionSections,
} from 'components/dashboard/Metrics/Create/constants';
import { FlexColumn } from 'components/dashboard/Metrics/Create/styles';
import {
  evaluateWidgetDataIsIncluded,
  isBIMetricSimple,
  isMetricFiltersValid,
  isObjectIncluded,
} from 'components/dashboard/Metrics/Create/utils';
import { RevBiQuickView } from 'components/dashboard/Metrics/QuickView/RevBiQuickView';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import {
  SidebarType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  dispatchIfNotAsked,
  formatWidgetForSaving,
  formatWidgetForUpdating,
  parseWidget,
} from 'components/dashboard/Metrics/metrics.helpers';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIDashboard,
  BIMetricCreated,
  BIMetricToChartType,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';
import { fetchApi, QueryMethod } from 'utils/network';
import { fetchApiWithoutCb } from 'utils/network/fetchApiWithoutCb';

export const WidgetCreate: React.FC = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const match = useRouteMatch<{ widgetId: string }>();

  const [saveRedirect] = useQueryParam('saveRedirect', StringParam);
  const [addWidgetToDashboard] = useQueryParam(
    'addWidgetToDashboard',
    StringParam
  );

  // we need a copy of the mock, because when we push items inside of the array props, it can be persisted.
  const startWidget = useRef<BIWidget>(
    JSON.parse(JSON.stringify(NEW_WIDGET_MOCK))
  );

  const [previewWidget, setPreviewWidget] = useState<BIWidget>(
    JSON.parse(JSON.stringify(NEW_WIDGET_MOCK))
  );

  const [newWidget, setNewWidget] = useState<BIWidget>(
    JSON.parse(JSON.stringify(NEW_WIDGET_MOCK))
  );
  const [touched, setTouched] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isTopMetricsSidebarOpen, setIsTopMetricsSidebarOpen] =
    useState<boolean>(false);
  const [isCreateOrEditMetricMode, setIsCreateOrEditMetricMode] =
    useState<boolean>(false);
  const [sectionExpanded, setSectionExpanded] = useState<OptionSections>(() =>
    match.params.widgetId ? OptionSections.METRIC : OptionSections.TYPE
  );

  // selectors
  const objectListHistoryStatus = useSelector(
    metricSelectors.getObjectsListHistoryStatus
  );
  const objectListStatus = useSelector(metricSelectors.getObjectListStatus);

  const historicalMetricsListStatus = useSelector(
    metricSelectors.getMetricsTSListStatus
  );
  const historicalMetricList = useSelector(metricSelectors.getMetricsTSList);

  const metricsListStatus = useSelector(metricSelectors.getMetricsListStatus);
  const metricList = useSelector(metricSelectors.getMetricsList);

  const timeOptionsStatus = useSelector(metricSelectors.getTimeOptionsStatus);
  const quarterOptionsStatus = useSelector(
    metricSelectors.getQuarterForecastPeriodOptionsStatus
  );
  const monthOptionsStatus = useSelector(
    metricSelectors.getMonthForecastPeriodOptionsStatus
  );
  const weekOptionsStatus = useSelector(
    metricSelectors.getWeekForecastPeriodOptionsStatus
  );
  // selectors end

  // memos
  const [canSave, canPreview]: boolean[] = useMemo(() => {
    const canPreview =
      !!newWidget.metric_list?.length ||
      newWidget.analysis_type === AnalysisType.FUNNEL;

    const widgetHasName = !!newWidget?.name;
    const isInvalidWidget = !widgetHasName || !canPreview;

    // here we will check if the report widget has been changed or not.
    const isObjIncluded = isObjectIncluded(newWidget, startWidget.current);
    const filtersLengthIdentical =
      newWidget.metric_list?.length === startWidget.current.metric_list?.length;
    const widgetFiltersLength =
      newWidget.widget_filters.length ===
      startWidget.current.widget_filters.length;
    const allMetricAreSaved: boolean = newWidget.metric_list.reduce(
      (acc: boolean, metric: BIMetricCreated) =>
        acc && metric._id !== NOT_SAVED_METRIC,
      true
    );

    const canSave =
      !isInvalidWidget &&
      allMetricAreSaved &&
      !(isObjIncluded && filtersLengthIdentical && widgetFiltersLength);

    return newWidget.analysis_type !== AnalysisType.REPORT
      ? [canSave, canPreview]
      : [true, false];
  }, [newWidget, startWidget.current, newWidget.metric_list]);

  const [
    canSaveReportWidget,
    canPreviewReportWidget,
    isValidReportViewInput,
  ]: boolean[] = useMemo(() => {
    const canPreview = Boolean(
      (sectionExpanded === OptionSections.METRIC ||
        sectionExpanded === OptionSections.TEMPLATE_FILTERS) &&
        newWidget?.order_by_column?.name
    );

    // checking if all filters are completed
    const areFiltersCompleted =
      !newWidget.metric_list?.[0]?.filters?.length ||
      isMetricFiltersValid(newWidget.metric_list?.[0]);

    const widgetLimit = newWidget?.limit || 0;

    const isValidInput: boolean = Boolean(
      newWidget.analysis_type === AnalysisType.REPORT &&
        canPreview &&
        widgetLimit >= 0 &&
        areFiltersCompleted
    );

    // here we will check if the report widget has been changed or not.
    const stillIncluded = isObjectIncluded(newWidget, startWidget.current);
    const filtersLength =
      newWidget.metric_list?.[0]?.filters.length ===
      (startWidget.current.metric_list &&
        startWidget.current.metric_list[0]?.filters.length);

    const canSave = Boolean(
      newWidget?.name && isValidInput && !(stillIncluded && filtersLength)
    );

    return newWidget.analysis_type === AnalysisType.REPORT
      ? [canSave, canPreview, isValidInput]
      : [true, false, false];
  }, [newWidget, sectionExpanded]);

  const [canSaveFunnel, canPreviewFunnel, isValidFunnelInput]: boolean[] =
    useMemo(() => {
      const isValidInput =
        newWidget.funnel_stage_column !== undefined &&
        newWidget.funnel_stages !== undefined &&
        newWidget.funnel_stages.length > 0;

      const canSave =
        Boolean(
          newWidget?.name &&
            isValidInput &&
            !isObjectIncluded(newWidget, startWidget.current)
        ) || touched;

      const canPreview = isValidInput;

      return newWidget.analysis_type === AnalysisType.FUNNEL
        ? [canSave, canPreview, isValidInput]
        : [true, true, false];
    }, [newWidget]);

  const selectedMetricsIds: string[] = useMemo(
    () =>
      // for now we will take funnel metric list that is an array of strings.
      newWidget.analysis_type === AnalysisType.FUNNEL
        ? newWidget.funnel_metric_list
        : newWidget.metric_list?.reduce(
            (results: string[], item: BIMetricCreated) => {
              if (item?._id) results.push(item._id);
              return results;
            },
            []
          ) || [],
    [newWidget.metric_list, newWidget.funnel_metric_list]
  );

  // this a the list of metrics available based on the analysis type
  const candidateMetricList = useMemo(() => {
    if (newWidget.analysis_type === AnalysisType.HISTORICAL) {
      return historicalMetricList;
    }

    if (newWidget.analysis_type === AnalysisType.FUNNEL) {
      return FUNNEL_PREDEFINED_METRICS;
    }

    /**
     * if the first pivot is related to period interval
     * we will remove all the metric that doesn't have a
     * linked date field.
     */
    if (newWidget.group_by[0] && !newWidget.time_field?.name) {
      const pivot = newWidget.group_by[0];
      if (pivot.name === 'month') {
        return metricList.filter((metric) => {
          if (isBIMetricSimple(metric)) {
            return !!metric.date_field?.name;
          }
          return false;
        });
      }
    }

    return metricList;
  }, [
    newWidget.analysis_type,
    historicalMetricList,
    metricList,
    newWidget.metric_list,
    newWidget.group_by,
  ]);

  const availablePreviewWidgets = useMemo(() => {
    const isWidgetDataIncluded = evaluateWidgetDataIsIncluded(
      newWidget,
      previewWidget
    );
    if (newWidget.analysis_type === AnalysisType.REPORT) {
      return !isWidgetDataIncluded && !isWidgetDataIncluded;
    }
    if (newWidget.analysis_type === AnalysisType.FUNNEL) {
      return canPreviewFunnel && !isWidgetDataIncluded;
    }
    return canPreview && !isWidgetDataIncluded;
  }, [
    previewWidget,
    newWidget,
    newWidget.analysis_type,
    canPreview,
    canPreviewReportWidget,
    canPreviewFunnel,
  ]);

  const pushNewPreviewWidget = useCallback((): void => {
    setPreviewWidget({ ...newWidget });
  }, [newWidget]);

  /*
   * On mount hook to fetch the widget if it is opening one.
   * And to dispatch are request for Redux if there are not already.
   */
  useEffect(() => {
    if (match.params.widgetId) {
      fetchApiWithoutCb<any, BIWidget>({
        queryMethod: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${match.params.widgetId}?expand=true`,
      }).then(({ result: widget }) => {
        if (widget) {
          startWidget.current = JSON.parse(JSON.stringify(widget));
          setPreviewWidget(widget);
          setNewWidget(widget);
        } else {
          history.replace('/revbi/widgets');
          toast.error(`Failed to load the widget`);
        }
      });
    }

    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchObjectList,
      objectListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeSeriesObjectList,
      objectListHistoryStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchAllMetrics,
      metricsListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchAllTSMetrics,
      historicalMetricsListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeOptions,
      timeOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchQuarterForecastPeriodTimeOptions,
      quarterOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchMonthForecastPeriodTimeOptions,
      monthOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchWeekForecastPeriodTimeOptions,
      weekOptionsStatus
    );
  }, []);

  /**
   * when the user change the analysis type also change the base configuration
   * that's why we need to update the start *
   */
  useEffect(() => {
    if (startWidget.current.analysis_type !== newWidget.analysis_type) {
      startWidget.current = JSON.parse(
        JSON.stringify({ ...startWidget.current, ...newWidget })
      );
    }
  }, [newWidget.analysis_type]);
  // effects end

  // Metric Interactions
  const handleAddMetric = (metric: BIMetricCreated): void => {
    if (metric._id.startsWith('__FUNNEL__')) {
      setNewWidget((prev) => ({
        ...prev,
        funnel_metric_list: [...(prev.funnel_metric_list ?? []), metric._id],
      }));
    } else {
      setNewWidget((prev) => {
        let newPrev = { ...prev };
        const metricsCount = (newPrev?.metric_list?.length ?? 0) + 1;
        const tempMetricToChartType =
          newPrev.properties?.metricToChartType ?? [];
        let newMetricToChartType: BIMetricToChartType[] = [
          ...(newPrev.properties?.metricToChartType ?? []),
          {
            chartType:
              tempMetricToChartType[0]?.chartType ?? VisualizationType.Table,
            metricId: metric._id,
          },
        ];

        if (metricsCount > 4 && (newPrev.group_by?.length ?? -1) > 0) {
          newMetricToChartType = newMetricToChartType.map(
            (metric: BIMetricToChartType) => ({
              metricId: metric.metricId,
              chartType: VisualizationType.Table,
            })
          );
        }

        return {
          ...newPrev,
          metric_list: [...newPrev.metric_list, metric],
          properties: {
            metricToChartType: newMetricToChartType,
          },
        };
      });
    }
  };

  const handleAddTopMetric = (metric: BIMetricCreated): void => {
    const topMetrics = newWidget.funnel_top_metrics || [];
    handleNewWidgetConfig({
      funnel_top_metrics: [...topMetrics, metric._id],
    });
  };

  const handleSaveWidget = (): void => {
    let queryMethod: QueryMethod = 'post';
    let url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`;
    let queryParams;

    if (newWidget._id) {
      queryMethod = 'put';
      url = `${url}/${newWidget._id}`;
      queryParams = formatWidgetForUpdating(newWidget);
    } else {
      queryParams = formatWidgetForSaving(newWidget);
    }

    fetchApi<BIWidget, BIWidget>({
      url,
      queryMethod,
      queryParams,
      setData: (result) => {
        if (result) {
          if (newWidget._id) {
            dispatch(metricActions.updateWidget(parseWidget(result)));
          } else {
            dispatch(metricActions.addWidget(parseWidget(result)));
          }
        }

        if (addWidgetToDashboard) {
          // Dashboard are not persisted as a Dictionary
          // so we need to fetch the dashboard.
          const dashboardURL = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${addWidgetToDashboard}`;
          fetchApiWithoutCb<undefined, BIDashboard>({
            queryMethod: 'get',
            url: dashboardURL,
          }).then(({ result: dashboardToChange }) => {
            const changedDashboard = dashboardToChange && {
              ...dashboardToChange,
              widget_list: dashboardToChange.widget_list.concat(
                result?._id ?? ''
              ),
            };

            fetchApiWithoutCb({
              queryMethod: 'put',
              url: dashboardURL,
              queryParams: changedDashboard,
            }).then(() => {
              history.push(saveRedirect ?? '/revbi/widgets/list');
            });
          });
        } else {
          toast.success('Widget saved.');
          history.push(saveRedirect ?? '/revbi/widgets/list');
        }
      },
      setError: (error) => {
        console.error(error);
        toast.error('Error! Failed to save widget.');
      },
    });
  };

  const handleCloneAddMetric = (id: string): void => {
    if (id) {
      toast.error(`Cloning metric...`);
      fetchApi<void, BIMetricCreated>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${id}/clone`,
        queryMethod: 'get',
        setData: (result) => {
          // we need to rethink this... we are loading all the metrics again.
          dispatch(metricActions.fetchAllMetrics());
          dispatch(metricActions.fetchAllTSMetrics());
          newWidget.metric_list.push(result);
        },
        setError: () => {
          toast.error(`Failed to clone metric`);
        },
      });
    }
  };

  const handleCreateNewMetric = (): void => {
    setIsCreateOrEditMetricMode(true);
    setIsSidebarOpen(false);
  };

  const handleNameChange = (name: string): void =>
    setNewWidget((prev) => prev && { ...prev, name });

  const handleNewWidgetConfig = (
    updatedWidgetConfig: Partial<BIWidget>
  ): void => {
    /**
     * if the user is removing a metric, it should mark the widget touched.
     */
    if (
      Object.keys(updatedWidgetConfig).includes('metric_list') &&
      updatedWidgetConfig.metric_list?.length !== newWidget.metric_list?.length
    ) {
      setTouched(true);
    }

    if (
      JSON.stringify(updatedWidgetConfig.properties?.metricToChartType) !==
        JSON.stringify(previewWidget.properties?.metricToChartType) &&
      Object.keys(updatedWidgetConfig).length === 2 &&
      ['chart_type', 'properties'].every((key) =>
        Object.keys(updatedWidgetConfig).includes(key)
      )
    ) {
      setPreviewWidget(
        (prev: BIWidget) =>
          prev && {
            ...prev,
            ...updatedWidgetConfig,
          }
      );
    }
    setNewWidget(
      (prev: BIWidget) =>
        prev && {
          ...prev,
          ...updatedWidgetConfig,
        }
    );
  };

  if (match.params.widgetId && !newWidget._id) {
    return <Loader active content="Loading" />;
  }

  return (
    <UsersByActivityProvider includeDisabledUsers={false}>
      <FlexColumn>
        <Header
          id={newWidget?.id}
          name={newWidget?.name}
          type={CreationType.WIDGET}
          isSaveDisabled={!canSave || !canSaveReportWidget || !canSaveFunnel}
          onNameChange={handleNameChange}
          onClone={() => {}}
          onDelete={() => {}}
          onSave={handleSaveWidget}
          onPreview={() => {
            pushNewPreviewWidget();
          }}
          canPreview={availablePreviewWidgets}
          hidePreviewButton={newWidget.analysis_type === AnalysisType.REPORT}
        />
        <FlexRow cssProps={{ height: '100%' }}>
          <WidgetCreateOptions
            sectionExpanded={sectionExpanded}
            widget={newWidget}
            isValidReportViewInput={isValidReportViewInput}
            isValidFunnelInput={isValidFunnelInput}
            isCreateOrEditMetricMode={isCreateOrEditMetricMode}
            setSectionExpanded={setSectionExpanded}
            setIsCreateOrEditMetricMode={setIsCreateOrEditMetricMode}
            setIsSidebarOpen={setIsSidebarOpen}
            setIsTopMetricsSidebarOpen={setIsTopMetricsSidebarOpen}
            onCompleteOptions={handleNewWidgetConfig}
          />

          <WidgetPreview
            widget={newWidget}
            previewWidget={previewWidget}
            canPreviewReportWidget={canPreviewReportWidget}
            canPreviewFunnelWidget={canPreviewFunnel}
            canPreviewLiveOrHistoricalWidget={canPreview}
            isCreateOrEditMetricMode={isCreateOrEditMetricMode}
            onUpdateWidget={handleNewWidgetConfig}
          />
        </FlexRow>
      </FlexColumn>

      {isSidebarOpen && (
        <RevBiQuickView
          isOpen={isSidebarOpen}
          list={candidateMetricList}
          analysisType={newWidget?.analysis_type as AnalysisType}
          sidebarType={SidebarType.METRICS}
          selectedIds={selectedMetricsIds}
          onClose={() => setIsSidebarOpen(false)}
          onAdd={handleAddMetric}
          onCloneAddMetric={handleCloneAddMetric}
          onCreateNew={handleCreateNewMetric}
        />
      )}

      {isTopMetricsSidebarOpen && (
        <RevBiQuickView
          isOpen={isTopMetricsSidebarOpen}
          list={FUNNEL_PREDEFINED_TOP_METRICS}
          analysisType={newWidget?.analysis_type as AnalysisType}
          sidebarType={SidebarType.METRICS}
          selectedIds={newWidget.funnel_top_metrics}
          onClose={() => setIsTopMetricsSidebarOpen(false)}
          onAdd={handleAddTopMetric}
          onCreateNew={() => {}}
        />
      )}
    </UsersByActivityProvider>
  );
};
