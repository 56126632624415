import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { BaseHistoricalWidget } from 'components/dashboard/Metrics/Widget/Historical/base/BaseHistoricalWidget';
import {
  openChartDrilldownModal,
  openTableDrilldownModal,
} from 'components/dashboard/Metrics/Widget/Historical/drilldown.helpers';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIDashboardSettings,
  BIMetricCreated,
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { RevBISettingsContext } from '../../contexts/RevBISettingsContext';
import { OnChartDataClick } from '../Chart/WidgetChart.types';
import { useCacheMutation } from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from '../hooks/useRevBiDrilldownModal';

interface Props {
  widget: BIWidget;
  dashboardId?: string;
  dashboardName?: string;
  isDashboardModal?: boolean;
  isReadOnly?: boolean;
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<BIWidget>>;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
}

export const HistoricalWidgetDashboard: React.FC<Props> = ({
  widget,
  dashboardId = '',
  dashboardName = '',
  isDashboardModal = false,
  isReadOnly = false,
  dashboardSettings,
  setWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
}) => {
  const dispatch = useDispatch();

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const { waitingTimeToRefreshWidgetSeconds } =
    useContext(RevBISettingsContext);

  /**
   * this is a hack in order to reduce the numbers of call to API
   * Temporary fix.
   */
  const [blockingRefreshUntil, setBlockingRefreshUntil] = useState(0);

  const metricsInUse = useMemo(
    () =>
      widget.metric_list.reduce(
        (acc: BIMetricsMap, metric: BIMetricCreated) => {
          const key = metric?._id;
          acc[key] = metric;

          return acc;
        },
        {}
      ),
    [JSON.stringify(widget.metric_list)]
  );

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const metricsBusinessType: string = useMemo(() => {
    let businessType = '';
    (widget.metric_list as BIMetricCreated[])?.forEach((m) => {
      m.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    });

    return businessType;
  }, [JSON.stringify(widget.metric_list)]);

  const areHistoryFieldsValid = Boolean(
    widget.time_period && widget.time_interval && widget.point_in_time
  );

  const queryWidget = {
    ...widget,
    ...dashboardSettings,
    dashboard_id: dashboardId,
    dashboard_name: dashboardName,
  };

  const widgetUrlQuery = {
    user_status: dashboardSettings?.userFilter,
  };

  const isTableVisualization = widget?.properties?.metricToChartType?.some(
    (el) => el.chartType === 'table'
  );

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: queryWidget,
    hookEnabled: areHistoryFieldsValid,
    urlQuery: widgetUrlQuery,
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'edition',
  });

  const { cleanCacheMutation, fetchPivotDelayedBy } = useCacheMutation(
    widget._id ?? '',
    widgetUrlQuery
  );

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const presentBusinessType = widgetFiltersBusinessType || metricsBusinessType;

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = column.metricId ?? column.field.split('|')[0];

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetricCreated = {
        ...metricsInUse[metricId],
        name: column.label,
      };

      openTableDrilldownModal(
        clickedMetric,
        column,
        row,
        widget,
        // We removed v2 so this is not needed anymore
        // openTableDrillDownModal Should be refactored
        undefined,
        presentBusinessType,
        dispatch,
        openRevBiDrilldownModalWithOnClose
      );
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };
        openChartDrilldownModal(
          metricClicked,
          selectedValue,
          widget,
          // We removed v2 so this is not needed anymore
          // openTableDrillDownModal Should be refactored
          treeWidget,
          presentBusinessType,
          dispatch,
          openRevBiDrilldownModalWithOnClose
        );
      }
    }
  };

  const handleUpdateWidget = (changes: Partial<BIWidget>) => {
    setWidget((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  /**
   * this is a hack in order to lock the refresh button during 15s
   * before the table is recently updated.
   */
  useEffect(() => {
    if (!isTableRefetching && hierarchicalStatus === 'success') {
      const d = new Date(Date.now() + waitingTimeToRefreshWidgetSeconds * 1000);
      setBlockingRefreshUntil(d.valueOf());
    }
  }, [isTableRefetching, hierarchicalStatus]);

  const cleanCacheAndRefresh = async () => {
    if (!isTableRefetching && !cleanCacheMutation.isLoading) {
      try {
        await cleanCacheMutation.mutateAsync();
        fetchPivotDelayedBy(widget.group_by.length, refetchLoadedSubtrees);
      } catch {
        toast.error('Max amount of requests exceeded. Try again in 1 minute.');
        console.error('clean cache error.');
      }
    }
  };

  return (
    <BaseHistoricalWidget
      isDashboardWidget
      isControlsShown={isDashboardModal}
      isDashboardModal={isDashboardModal}
      isReadOnly={isReadOnly}
      dashboardName={dashboardName}
      metricsInUse={metricsInUse}
      widget={widget}
      isTableRefetching={isTableRefetching}
      widgetData={treeWidget}
      widgetDataStatus={hierarchicalStatus}
      onCloneWidget={onCloneWidget}
      onEditWidget={onEditWidget}
      onRemoveWidget={onRemoveWidget}
      onTitleClicked={onTitleClicked}
      onCloseWidgetModal={onCloseWidgetModal}
      onChartDataClick={handleChartDataClick}
      onTableDataClick={handleTableDataClick}
      onUpdateWidget={handleUpdateWidget}
      addSubTreeToFetch={addSubTreeToFetch}
      previewWidget={widget}
    />
  );
};
