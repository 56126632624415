import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { BaseHistoricalWidget } from 'components/dashboard/Metrics/Widget/Historical/base/BaseHistoricalWidget';
import {
  openChartDrilldownModal,
  openTableDrilldownModal,
} from 'components/dashboard/Metrics/Widget/Historical/drilldown.helpers';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
  BIMetricsMap,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { OnChartDataClick } from '../Chart/WidgetChart.types';
import { useCacheMutation } from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from '../hooks/useRevBiDrilldownModal';

interface Props {
  metric: BIMetricUnion;
  widget: BIWidget;
  previewWidget: BIWidget;
  setWidget: Dispatch<SetStateAction<BIWidget>>;
}

export const HistoricalWidget: React.FC<Props> = ({
  metric,
  widget,
  previewWidget,
  setWidget,
}) => {
  const dispatch = useDispatch();

  const metricKey = metric._id;

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const metricsInUse: BIMetricsMap = {
    [metricKey]: metric as BIMetricCreated,
  };

  const metricBusinessType: string = useMemo(() => {
    let businessType = '';

    if (metric) {
      metric?.filters?.forEach((f: BIMetricsQueryFilter) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    }

    return businessType;
  }, [metric?.filters]);

  const isMetricValid = Boolean(
    ((metric as BIMetricSimple)?.aggregation_function &&
      (metric as BIMetricSimple)?.column) ||
      (metric as BIMetricSimple)?.aggregation_function === 'count' ||
      (metric as BIMetricFormula)?.synthetic_metric
  );

  const areHistoryFieldsValid = Boolean(
    widget.time_period && widget.time_interval && widget.point_in_time
  );

  const isTableVisualization = widget?.properties?.metricToChartType?.some(
    (el) => el.chartType === 'table'
  );

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: widget,
    hookEnabled: areHistoryFieldsValid && isMetricValid,
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'edition',
  });

  const { fetchPivotDelayedBy } = useCacheMutation(widget._id ?? '');

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const clickedMetric = metric as BIMetricCreated;

    if (clickedMetric) {
      openTableDrilldownModal(
        clickedMetric,
        column,
        row,
        widget,
        // We removed v2 so this is not needed anymore
        // openTableDrillDownModal Should be refactored
        undefined,
        metricBusinessType,
        dispatch,
        openRevBiDrilldownModalWithOnClose
      );
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };
        openChartDrilldownModal(
          metricClicked,
          selectedValue,
          widget,
          // We removed v2 so this is not needed anymore
          // openTableDrillDownModal Should be refactored
          treeWidget,
          metricBusinessType,
          dispatch,
          openRevBiDrilldownModalWithOnClose
        );
      }
    }
  };

  const handleUpdateWidget = (
    changes: Partial<BIWidget>,
    isEditMode?: boolean
  ) => {
    if (isEditMode) {
      setWidget((prev) => ({
        ...prev,
        ...changes,
      }));
    } else {
      setWidget({
        ...widget,
        ...changes,
      });
    }
  };

  const cleanCacheAndRefresh = () => {
    if (!isTableRefetching) {
      fetchPivotDelayedBy(widget.group_by.length, refetchLoadedSubtrees);
    }
  };

  return (
    <BaseHistoricalWidget
      isControlsShown
      isMetricPreview
      metricsInUse={metricsInUse}
      widget={widget}
      previewWidget={previewWidget}
      isTableRefetching={isTableRefetching}
      widgetData={treeWidget}
      widgetDataStatus={hierarchicalStatus}
      onChartDataClick={handleChartDataClick}
      onTableDataClick={handleTableDataClick}
      onUpdateWidget={handleUpdateWidget}
      addSubTreeToFetch={addSubTreeToFetch}
    />
  );
};
