import {
  AnalysisType,
  FORECAST_SUBMISSION,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { MetricType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricFormula,
  BIMetricFormulaNewborn,
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIMetricUnion,
  BIMetricsFilter,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

/**
 *
 *  Metric Validation Type - Section
 */

export const isBIMetricSimple = (
  metric:
    | Partial<BIMetricSimple>
    | Partial<BIMetricFormula>
    | Partial<BIMetricFormulaNewborn>
    | Partial<BIMetricSimpleNewborn>
    | Partial<BIMetricUnion>
): metric is BIMetricSimple => metric?.metadata?.type === 'simple';

export const isBIMetricFormula = (
  metric:
    | BIMetricSimple
    | BIMetricFormula
    | BIMetricFormulaNewborn
    | BIMetricSimpleNewborn
    | BIMetricUnion
): metric is BIMetricFormula => metric?.metadata?.type === 'formula';

export const isBIHistoricalWidget = (widget: BIWidget): widget is BIWidget =>
  widget.analysis_type === AnalysisType.HISTORICAL;

/** ends */

export const getMetricType = (
  metric:
    | BIMetricSimple
    | BIMetricFormula
    | BIMetricFormulaNewborn
    | BIMetricSimpleNewborn
): MetricType => {
  return metric.metadata?.type;
};

const isValidFilter = (filter: Partial<BIMetricsFilter>): boolean => {
  return Boolean(
    filter?.column &&
      filter?.operator &&
      filter?.value !== undefined &&
      filter?.value !== null &&
      filter?.value !== ''
  );
};

export const isMetricFiltersValid = (
  metric: BIMetricSimpleNewborn | BIMetricFormulaNewborn
) => {
  return metric.filters.reduce((pre: boolean, filter: BIMetricsQueryFilter) => {
    return pre && isValidFilter(filter?.and_condition?.[0]?.or_condition?.[0]);
  }, true);
};

export const isSimpleMetricCanPreview = (
  metric: BIMetricSimple | BIMetricSimpleNewborn
) => {
  if (metric.object === TARGET) {
    return true;
  } else if (metric.object === FORECAST_SUBMISSION) {
    return Boolean(metric.forecast_submission_properties?.metric_type);
  } else {
    return Boolean(metric.column);
  }
};

export const isFormulaMetricCanPreview = (
  metric: BIMetricFormula | BIMetricFormulaNewborn
) => {
  return Boolean(metric.synthetic_metric);
};

export const isSimpleMetricValid = (
  metric: BIMetricSimple | BIMetricSimpleNewborn,
  checkDateField: boolean
) => {
  const isNameValid = typeof metric.name === 'string' && metric.name.length > 0;
  const dateFieldCorrect = checkDateField ? metric.date_field : true;
  return isSimpleMetricCanPreview(metric) && dateFieldCorrect && isNameValid;
};

export const isFormulaMetricValid = (
  metric: BIMetricFormula | BIMetricFormulaNewborn
) => {
  const isNameValid = typeof metric.name === 'string' && metric.name.length > 0;
  return isFormulaMetricCanPreview(metric) && isNameValid;
};

export const isObjectIncluded = (
  object: any = {},
  container: any = {},
  pResult: boolean = true
): boolean => {
  let result = pResult;
  const objectKey = Object.keys(object);
  const containerKey = Object.keys(container);

  objectKey.forEach((key) => {
    if (typeof object[key] === 'object' && result) {
      // if the key exists but is null in both cases, the result should be true
      if (container[key] ?? object[key] ?? false) {
        const isObjectArray = Array.isArray(object[key]);
        const isContainerArray = Array.isArray(container[key]);
        const bothArray = isObjectArray && isContainerArray;
        const areSameLengthIfArray = bothArray
          ? object[key].length === container[key].length
          : true;

        result =
          !!container[key] &&
          !!object[key] &&
          areSameLengthIfArray &&
          isObjectIncluded(object[key], container[key], result);
      } else {
        result = true;
      }
    } else if (containerKey.includes(key)) {
      const catchedObjectValue = object[key] ?? ''; // in order to avoid undefined and nulls
      const catchedContainerValue = container[key] ?? ''; // in order to avoid undefined and nulls
      result = result && catchedObjectValue === catchedContainerValue;
    } else {
      result =
        result && container[key] === undefined && object[key] === undefined;
    }
  });
  return result;
};

export const evaluateWidgetDataIsIncluded = (
  widget: Partial<BIWidget>,
  previewWidget: Partial<BIWidget>
): boolean => {
  const mapMetricList = (
    metricList: BIMetricUnion[]
  ): Partial<BIMetricUnion>[] => {
    return metricList.map((metric) => {
      const { name, name_identifier, ...returnMetric } = metric;

      return returnMetric;
    });
  };

  const extractWidgetData = (widget: Partial<BIWidget>) => {
    const {
      analysis_type,
      advanced_configurations,
      compute_user_hierarchy_response,
      group_by,
      metric_list = [],
      point_in_time,
      template_filters = [],
      time_field,
      time_period,
      widget_filters = [],
      funnel_conditions = [],
      funnel_metric_list = [],
      funnel_object = '',
      funnel_stage_column = '',
      funnel_stages = [],
      funnel_top_metrics = [],
    } = widget;

    return {
      analysis_type,
      advanced_configurations,
      compute_user_hierarchy_response,
      group_by,
      metric_list: mapMetricList(metric_list),
      point_in_time,
      template_filters,
      time_field,
      time_period,
      widget_filters,
      funnel_conditions,
      funnel_metric_list,
      funnel_object,
      funnel_stage_column,
      funnel_stages,
      funnel_top_metrics,
    };
  };

  return isObjectIncluded(
    extractWidgetData(widget ?? {}),
    extractWidgetData(previewWidget ?? {})
  );
};
