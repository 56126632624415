import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useIsMutating } from '@tanstack/react-query';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { MetricsWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetControls';
import {
  RefreshButtonConfiguration,
  WidgetHeader,
} from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { WidgetVisualization } from 'components/dashboard/Metrics/Widget/Visualization/WidgetVisualization';
import {
  everyMetricsHaveDateField,
  getMetricsNameAndValueUsedInFormula,
  getSyntheticMetricData,
  getWidgetMetricDisplayNameMap,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { useFormulaMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useFormulaMetricsList';
import {
  createDrillDownParams,
  openDrillDownModal,
  openTableDrillDownModal,
  openTotalDrilldownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { SyntheticMetricDataWidgetState } from 'components/dashboard/Metrics/Widget/types';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BUSINESS_TYPES_FIELD_NAMES,
  FORECAST_SUBMISSION,
  GENERIC_TIME_INTERVAL_PIVOTS,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  BIDashboardSettings,
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricsMap,
  BIMetricToChartType,
  BIPivotDescriptor,
  BIWidget,
  DrillDownParams,
  ExternalFieldsRequestParams,
} from 'components/dashboard/Metrics/metrics.types';
import { getMetricsList } from 'selectors/revbi/metrics';
import { getFeatureFlag } from 'selectors/settings';
import { fetchApi } from 'utils/network';
import { canOpenADrilldown, getWidgetObjects } from '../metrics.helpers';
import { OnChartDataClick } from './Chart/WidgetChart.types';
import { ConfigClickExtraData } from './Table/helpers/columnsHelpers';
import { useCacheMutation } from './hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from './hooks/useRevBiDrilldownModal';

interface Props {
  widget: BIWidget;
  dashboardId?: string;
  dashboardName?: string;
  isDashboardModal?: boolean;
  isReadOnly?: boolean;
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<BIWidget>>;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
}

export const MetricsWidgetDashboard: React.FC<Props> = ({
  widget,
  dashboardId = '',
  dashboardName = '',
  isDashboardModal = false,
  isReadOnly = false,
  dashboardSettings,
  setWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
}) => {
  const dispatch = useDispatch();

  const {
    drilldownTableSettings: drilldown_table_settings,
    hierarchyAlias,
    waitingTimeToRefreshWidgetSeconds,
  } = useContext(RevBISettingsContext);

  const supportedCustomObjects = Object.keys(drilldown_table_settings);
  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const metricsList = useSelector(getMetricsList);
  const { revbiEmailsDrilldownEnabled, revbiEventsDrilldownEnabled } =
    useSelector((state) => {
      return {
        revbiEmailsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_emails_drilldown_enabled'
        ),
        revbiEventsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_events_drilldown_enabled'
        ),
      };
    });

  const metricsInUse = useMemo(
    () =>
      widget.metric_list.reduce((acc, metric) => {
        acc[metric._id] = metric;
        return acc;
      }, {} as BIMetricsMap),
    [widget.metric_list]
  );

  const metricToChartType = useMemo<BIMetricToChartType[]>(() => {
    const widgetPropMTCT = widget?.properties?.metricToChartType ?? [];

    if (widgetPropMTCT.length) {
      return widgetPropMTCT.filter(
        (mtcItem) =>
          !!widget.metric_list.find(
            (metric: BIMetricCreated) => metric._id == mtcItem.metricId
          )
      );
    } else {
      return widget.metric_list.map((el: string) => ({
        chartType: VisualizationType.Column,
        metricId: typeof el === 'string' ? el : (el as BIMetricCreated)?._id,
      }));
    }
  }, [JSON.stringify(widget?.properties?.metricToChartType)]);

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  const [availablePivots, setAvailablePivots] = useState<BIPivotDescriptor[]>(
    []
  );

  const [showMetrics, setShowMetrics] = useState(!widget.group_by.length);

  const usedFormulaMetricIds = useMemo(() => {
    return ((widget.metric_list as BIMetricCreated[]) || [])
      .filter((m) => m.metadata?.type === MetricType.Formula)
      .flatMap((m) =>
        parseFormulaToMetricsIdArray(
          (m as BIMetricFormula).synthetic_metric || ''
        )
      );
  }, [JSON.stringify(widget.metric_list)]);

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);

  /**
   * Hook to fetch available pivot options per selected metrics.
   */
  useEffect(() => {
    const objectsSet = new Set<string>();

    const abortControllerFirstPivot = new AbortController();
    const abortControllerSecondPivot = new AbortController();

    const simpleMetrics = (widget.metric_list as BIMetricSimple[])?.filter(
      (m) => m.metadata?.type === MetricType.Simple
    );

    if (simpleMetrics?.length) {
      simpleMetrics.forEach((m) => {
        const metricObject = m.object;

        if (metricObject) {
          objectsSet.add(metricObject);
        }
      });
    }

    Object.keys(usedFormulaMetricsMap).forEach((element) => {
      const metricObject = (usedFormulaMetricsMap[element] as BIMetricSimple)
        .object;

      if (metricObject) {
        objectsSet.add(metricObject);
      }
    });

    if (objectsSet?.size) {
      const queryParams = {
        table_names: [...objectsSet],
      };

      fetchApi<ExternalFieldsRequestParams, BIPivotDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/columns/pivots`,
        queryMethod: 'get',
        queryParams: queryParams,
        setData: (result) => {
          let cleanedResult = everyMetricsHaveDateField(widget)
            ? [...GENERIC_TIME_INTERVAL_PIVOTS, ...result]
            : [...result];

          setAvailablePivots(cleanedResult);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
        signal: abortControllerSecondPivot.signal,
      });
    }

    return () => {
      setAvailablePivots([]);
      abortControllerFirstPivot.abort();
      abortControllerSecondPivot.abort();
    };
  }, [
    JSON.stringify(widget.metric_list),
    JSON.stringify(usedFormulaMetricsMap),
  ]);

  const queryWidget = {
    ...widget,
    ...dashboardSettings,
    dashboard_id: dashboardId,
    dashboard_name: dashboardName,
  };

  const widgetUrlQuery = {
    user_status: dashboardSettings?.userFilter,
  };

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    isTreeWidgetCompatibleWithChartVisualization,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: queryWidget,
    urlQuery: widgetUrlQuery,
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'published',
  });

  const [blockingRefreshUntil, setBlockingRefreshUntil] = useState(0);

  const { cleanCacheMutation, fetchPivotDelayedBy } = useCacheMutation(
    widget._id ?? '',
    widgetUrlQuery
  );

  // checks if any mutation is running to lock the refresh button.
  const isMutatingInProgress = useIsMutating(['updateObjectField']);

  const isTargetMetricOnlyWidget = useMemo(
    () =>
      Object.keys(metricsInUse).length === 1 &&
      (metricsInUse[Object.keys(metricsInUse)[0]] as BIMetricSimple).object ===
        TARGET,
    [metricsInUse]
  );

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if ((current as BIMetricSimple).object === TARGET) {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  const firstForecastMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if ((current as BIMetricSimple).object === FORECAST_SUBMISSION) {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  /**
   * this is a hack in order to lock the refresh button during 15s
   * before the table is recently updated.
   */
  useEffect(() => {
    if (!isTableRefetching && hierarchicalStatus === 'success') {
      const d = new Date(Date.now() + waitingTimeToRefreshWidgetSeconds * 1000);
      setBlockingRefreshUntil(d.valueOf());
    }
  }, [isTableRefetching, hierarchicalStatus]);

  const cleanCacheAndRefresh = async () => {
    if (!isTableRefetching && !cleanCacheMutation.isLoading) {
      try {
        await cleanCacheMutation.mutateAsync();
        fetchPivotDelayedBy(widget.group_by.length, refetchLoadedSubtrees);
      } catch {
        toast.error('Max amount of requests exceeded. Try again in 1 minute.');
        console.error('clean cache error.');
      }
    }
  };

  const refreshButtonConfiguration = useMemo<RefreshButtonConfiguration>(
    () => ({
      cacheDate: treeWidget.updatedAt,
      showRefreshButton: isTableVisualization,
      refreshButtonLoading: isTableRefetching || cleanCacheMutation.isLoading,
      disabled: isMutatingInProgress > 0 || hierarchicalStatus === 'loading',
      onRefreshData: cleanCacheAndRefresh,
      targetDate: blockingRefreshUntil,
    }),
    [
      treeWidget.updatedAt,
      isTableVisualization,
      isTableRefetching,
      cleanCacheMutation.isLoading,
      isMutatingInProgress,
      cleanCacheAndRefresh,
      hierarchicalStatus,
      blockingRefreshUntil,
    ]
  );

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const handleTableDataClick = (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ): void => {
    let metricId = column.metricId ?? column.field.split('|')[0];
    let metricData = metricId in metricsInUse ? metricsInUse[metricId] : null;
    const subValueMetricId = column.config.subValue?.relativeField;

    if (extraData.subValueClicked && subValueMetricId) {
      // Be careful using this metricsList, sometimes it is not up to date
      metricId = subValueMetricId.split('|')[0];
      metricData = metricsList.find(({ _id }) => _id === metricId) || null;
    }

    if (metricData) {
      const columnsData = treeWidget.metricConfigurations;
      const columnData = columnsData.find((c) => c.field_name === metricId);
      const clickedMetric = {
        ...metricData,
        name: columnData?.display_name ?? metricData.name,
      };

      openTableDrillDownModal(
        clickedMetric as BIMetricCreated,
        column,
        dashboardSettings,
        widget?.group_by[0]?.name || '',
        revbiEmailsDrilldownEnabled,
        revbiEventsDrilldownEnabled,
        row,
        widget,
        widgetFiltersBusinessType,
        dispatch,
        openRevBiDrilldownModalWithOnClose,
        drilldown_table_settings,
        dashboardId
      );
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        const hasClickAction = canOpenADrilldown(
          metricClicked,
          supportedCustomObjects
        );
        if (!hasClickAction) {
          return;
        }
        const metricChartType = metricToChartType.find(
          (e) => e.metricId === metricClicked._id
        );

        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };

        openDrillDownModal(
          widget,
          selectedValue,
          metricClicked,
          widgetFiltersBusinessType,
          revbiEmailsDrilldownEnabled,
          revbiEventsDrilldownEnabled,
          dashboardSettings,
          // We removed v2 so this is not needed anymore
          // openTableDrillDownModal Should be refactored
          treeWidget,
          widget?.group_by.length ?? 0,
          widget?.group_by[0],
          widget?.group_by[1],
          metricChartType?.chartType || VisualizationType.Column,
          dispatch,
          openRevBiDrilldownModalWithOnClose,
          hierarchyAlias,
          drilldown_table_settings
        );
      }
    }
  };

  const handleTotalsClick = (metricId: string): void => {
    if (metricId in metricsInUse) {
      const columnsData = treeWidget.metricConfigurations;
      const columnData = columnsData.find((c) => c.field_name === metricId);
      const clickedMetric = {
        ...metricsInUse[metricId],
        name: columnData?.display_name ?? '',
      };

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric as BIMetricCreated,
        [],
        widgetFiltersBusinessType,
        widget,
        dashboardSettings
      );

      if ((clickedMetric as BIMetricFormula).synthetic_metric) {
        const totals = treeWidget.totals;

        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(
            (clickedMetric as BIMetricFormula).synthetic_metric
          );

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            clickedMetric as BIMetricFormula
          );

        let clickedValue = totals[metricId];

        openSyntheticMetricDetailsModal(
          columnData?.display_name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            y: clickedValue,
            metricsValues: getMetricsNameAndValueUsedInFormula(
              parsedClickedMetricFormulaAsArray,
              totals || {},
              metricDisplayNames
            ),
          },
          openRevBiDrilldownModalWithOnClose,
          drilldown_table_settings
        );
      } else {
        openTotalDrilldownModal(
          clickedMetric,
          drilldownParams,
          dispatch,
          openRevBiDrilldownModalWithOnClose,
          drilldown_table_settings,
          dashboardSettings
        );
      }
    }
  };

  const handleUpdateWidget = (changes: Partial<BIWidget>) => {
    setWidget((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  const isDateFirstPivot = ['date', 'timePeriod'].includes(
    widget?.group_by[0]?.type
  );

  const tables = getWidgetObjects(widget, usedFormulaMetricsMap);

  if (!isTreeWidgetCompatibleWithChartVisualization) {
    const newMetricToChartType = widget.properties?.metricToChartType?.map(
      (el) => {
        el.chartType = VisualizationType.Table;
        return el;
      }
    );

    setWidget({
      ...widget,
      properties: {
        ...widget.properties,
        metricToChartType: newMetricToChartType,
      },
    });
    toast.warn(
      'The dataset is too large for chart display. Visualization switched to table mode.'
    );
  }

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={true}
        isMetricsPreview={false}
      >
        <WidgetHeader
          isDashboardWidget
          id={widget._id}
          name={widget.name}
          dashboardName={dashboardName}
          isDashboardModal={isDashboardModal}
          showMetrics={showMetrics}
          optionalMetrics={!!widget.group_by.length}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={onTitleClicked}
          onCloseWidgetModal={onCloseWidgetModal}
          setShowMetrics={setShowMetrics}
          data-testing="widget-header"
          refreshButtonConfig={refreshButtonConfiguration}
          alternativeVisibility={
            !!widget.advanced_configurations?.user_visibility_name
          }
          showExpandButton={widget.group_by.length > 0}
        />

        {isDashboardModal && (
          <MetricsWidgetControls
            isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
            hasTargetMetric={Boolean(firstTargetMetric)}
            availablePivots={availablePivots}
            widget={widget}
            metricToChartType={metricToChartType}
            setWidget={setWidget}
            data-testing="metrics-widget-controls"
          />
        )}

        <TemplateFilters
          isDateFirstPivot={isDateFirstPivot}
          showControls={isDashboardModal}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          dashboardFilters={widget.dashboard_filters}
          tables={tables}
          targetPeriod={(firstTargetMetric as BIMetricSimple)?.target_period}
          onChangeWidget={(changes: Partial<BIWidget>) =>
            setWidget({ ...widget, ...changes })
          }
          data-testing="metrics-widget-filters"
        />

        <WidgetVisualization
          isTableRefetching={isTableRefetching}
          widgetDataStatus={hierarchicalStatus}
          isDashboardWidget={!isDashboardModal}
          isReadOnly={isReadOnly}
          metricsInUse={metricsInUse}
          metricToChartType={metricToChartType}
          showControls={isDashboardModal}
          widget={widget}
          widgetData={treeWidget}
          showMetrics={showMetrics}
          onUpdateWidget={handleUpdateWidget}
          onChartDataClick={handleChartDataClick}
          onTableDataClick={handleTableDataClick}
          onTotalsClick={handleTotalsClick}
          addSubTreeToFetch={addSubTreeToFetch}
          data-testing="metrics-widget-visualization"
        />
      </WidgetContainer>
    </>
  );
};
