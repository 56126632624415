import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { BaseHistoricalWidget } from 'components/dashboard/Metrics/Widget/Historical/base/BaseHistoricalWidget';
import {
  openChartDrilldownModal,
  openTableDrilldownModal,
} from 'components/dashboard/Metrics/Widget/Historical/drilldown.helpers';
import useHierarchicalWidgetFetching from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIMetricCreated,
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { OnChartDataClick } from '../Chart/WidgetChart.types';
import { useCacheMutation } from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  OpenRevBiDrilldownModal,
  useOpenRevBiDrilldownModal,
} from '../hooks/useRevBiDrilldownModal';

interface Props {
  readonly widget: BIWidget;
  readonly previewWidget: BIWidget;
  readonly isCreateEditMetric: boolean;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const HistoricalWidgetPreview: React.FC<Props> = ({
  widget,
  previewWidget,
  isCreateEditMetric = false,
  onChangeWidget,
}) => {
  const dispatch = useDispatch();

  const openRevBiDrilldownModal = useOpenRevBiDrilldownModal();

  const metricsInUse = useMemo(
    () =>
      widget.metric_list.reduce(
        (acc: BIMetricsMap, metric: BIMetricCreated) => {
          const key = (metric as BIMetricCreated)._id;
          acc[key] = metric;

          return acc;
        },
        {}
      ),
    [widget.metric_list]
  );

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const metricsBusinessType: string = useMemo(() => {
    let businessType = '';
    (widget.metric_list as BIMetricCreated[])?.forEach((m) => {
      m.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    });

    return businessType;
  }, [JSON.stringify(widget.metric_list)]);

  const areHistoryFieldsValid = Boolean(
    widget.time_period && widget.time_interval && widget.point_in_time
  );

  const isTableVisualization = widget?.properties?.metricToChartType?.some(
    (el) => el.chartType === 'table'
  );

  const {
    treeWidget,
    status: hierarchicalStatus,
    isTableRefetching,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  } = useHierarchicalWidgetFetching({
    widgetConfiguration: previewWidget,
    hookEnabled: areHistoryFieldsValid,
    urlQuery: { preview: 'preview' },
    widgetType: isTableVisualization ? 'table' : 'chart',
    widgetAction: 'edition',
  });

  const { fetchPivotDelayedBy } = useCacheMutation(widget._id ?? '');

  const openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal = (
    modalConfig
  ) => {
    openRevBiDrilldownModal(modalConfig, () => {});
  };

  const presentBusinessType = widgetFiltersBusinessType || metricsBusinessType;

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = column.metricId ?? column.field.split('|')[0];

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetricCreated = {
        ...metricsInUse[metricId],
        name: column.label,
      };

      openTableDrilldownModal(
        clickedMetric,
        column,
        row,
        widget,
        // We removed v2 so this is not needed anymore
        // openTableDrillDownModal Should be refactored
        undefined,
        presentBusinessType,
        dispatch,
        openRevBiDrilldownModalWithOnClose
      );
    }
  };

  const handleChartDataClick: OnChartDataClick = (pointEvent) => {
    const metricFieldNameClicked = pointEvent.metricId;

    if (metricFieldNameClicked) {
      const metricClicked = metricsInUse[metricFieldNameClicked];
      if (metricClicked) {
        // Remapping as we're not modifying openDrillDownModal
        const selectedValue = {
          metricId: pointEvent.metricId,
          pivot1Id: pointEvent.pivotValues[0],
          pivot2Id: pointEvent.pivotValues[1],
        };

        openChartDrilldownModal(
          metricClicked,
          selectedValue,
          widget,
          // We removed v2
          // openTableDrillDownModal Should be refactored
          treeWidget,
          presentBusinessType,
          dispatch,
          openRevBiDrilldownModalWithOnClose
        );
      }
    }
  };

  return (
    <BaseHistoricalWidget
      isControlsShown
      isMetricPreview={isCreateEditMetric}
      metricsInUse={metricsInUse}
      widget={widget}
      previewWidget={previewWidget}
      isTableRefetching={isTableRefetching}
      widgetData={treeWidget}
      widgetDataStatus={hierarchicalStatus}
      onChartDataClick={handleChartDataClick}
      onTableDataClick={handleTableDataClick}
      onUpdateWidget={onChangeWidget}
      addSubTreeToFetch={addSubTreeToFetch}
    />
  );
};
