import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { DropdownCalendar } from 'components/UI/OpenFiltersPanel/Dropdowns/Calendar';
import {
  ControlsContainer,
  FilterBarElement,
} from 'components/dashboard/Metrics/Widget/Controls/Historical/styles';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  DataDescriptor,
  BIPivotDescriptor,
  BIMetricToChartType,
  BIWidget,
  BIMetricFormula,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';
import { PivotControls } from '../PivotControls';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { getWidgetObjects } from 'components/dashboard/Metrics/metrics.helpers';
import { parseFormulaToMetricsIdArray } from '../../helper';
import { getMetricType } from 'components/dashboard/Metrics/Create/utils';
import { useFormulaMetricsList } from '../../hooks/useFormulaMetricsList';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  readonly widget: Partial<BIWidget>;
  readonly metricToChartType: BIMetricToChartType[];
  onUpdateWidget: (widget: Partial<BIWidget>, isEditMode?: boolean) => void;
}

export const HistoricalWidgetControls: React.FC<Props> = ({
  widget,
  metricToChartType,
  onUpdateWidget,
}) => {
  // TODO: workaround for the point in time being updated in the preview widget
  const match = useRouteMatch<{ metricId: string }>();
  const isEditMode = !!match.params.metricId;

  const { tableAliasesConfigs } = useContext(RevBISettingsContext);

  const [timeIntervalOptions, setTimeIntervalOptions] = useState<
    ISelectOption[]
  >([]);
  const [pointInTimeOptions, setPointInTimeOptions] = useState<ISelectOption[]>(
    []
  );
  const [timeValueOptions, setTimeValueOptions] = useState<
    Filters.DateFilterElement[]
  >([]);
  const [dateTemplateFilterOptions, setDateTemplateFilterOptions] = useState<
    Filters.DateFilterElement[]
  >([]);
  const [pivotColumns, setPivotColumns] = useState<BIPivotDescriptor[]>([]);

  useEffect(() => {
    const pivotColumnsAbortController = new AbortController();
    const timeIntervalAbortController = new AbortController();

    fetchApi<void, DataDescriptor[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_pivot_history`,
      queryMethod: 'get',
      setData: (result) => {
        setPivotColumns(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load pivot columns: ${error}`);
      },
      signal: pivotColumnsAbortController.signal,
    });

    fetchApi<void, DataDescriptor[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_time_interval_options/`,
      queryMethod: 'get',
      setData: (result) => {
        const resultOptions: ISelectOption[] = result.map((element) => ({
          text: element.label,
          value: element.name,
        }));

        setTimeIntervalOptions(resultOptions);

        if (!widget.time_interval) {
          onUpdateWidget({
            time_interval: resultOptions?.[0]?.value,
          });
        }
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load time interval options: ${error}`);
      },
      signal: timeIntervalAbortController.signal,
    });

    return () => {
      pivotColumnsAbortController.abort();
      timeIntervalAbortController.abort();
    };
  }, []);

  useEffect(() => {
    if (widget.time_interval) {
      const signalAbortPeriodOptions = new AbortController();
      const signalAbortTemplate = new AbortController();

      fetchApi<void, DataDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_time_period_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          const resultTimePeriodOptions: Filters.DateFilterElement[] = [];

          if (widget.time_period && widget.time_period.includes(',')) {
            resultTimePeriodOptions.push({
              label: widget.time_period.split(',').join(' / '),
              value: widget.time_period,
            });
          }

          result.map((element) => {
            resultTimePeriodOptions.push({
              label: element.label,
              value: element.name,
              type: element.type.toUpperCase() as Filters.DateElementType,
            });
          });

          setTimeValueOptions(resultTimePeriodOptions);

          const widgetTimePeriod = resultTimePeriodOptions.find(
            (timePeriod) => timePeriod.value === widget.time_period
          );

          onUpdateWidget({
            ...widget,
            time_period: widgetTimePeriod
              ? widgetTimePeriod.value
              : resultTimePeriodOptions[0]
              ? resultTimePeriodOptions[0].value
              : '',
          });
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load time period options: ${error}`);
        },
        signal: signalAbortPeriodOptions.signal,
      });

      const getType = (name: string): string => {
        if (name === 'all_time') return 'SAME_PERIOD';
        if (name.startsWith('same_')) return 'SAME_PERIOD';
        if (name.startsWith('previous_')) return 'PREVIOUS_PERIOD';
        if (name.startsWith('next_')) return 'NEXT_PERIOD';
        return '';
      };

      fetchApi<void, any[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_date_template_filter_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          setDateTemplateFilterOptions(
            result.map((element) => ({
              label: element.label,
              value: element.name,
              type: getType(element.name) as Filters.DateElementType,
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load date template filter options: ${error}`);
        },
        signal: signalAbortTemplate.signal,
      });

      return () => {
        signalAbortTemplate.abort();
        signalAbortPeriodOptions.abort();
      };
    }
  }, [widget.time_interval]);

  useEffect(() => {
    if (widget.time_interval && widget.time_period) {
      const signalAbort = new AbortController();

      fetchApi<void, DataDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_point_in_time_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          const resultPitOptions: ISelectOption[] = [];
          result.map((element) => {
            resultPitOptions.push({
              text: element.label,
              value: element.name,
            });
          });
          const widgetPointInTime =
            resultPitOptions.find(
              (pointInTime) => pointInTime.value === widget.point_in_time
            ) ?? false;

          // TODO: this is a workaround to update the widget when the user is editing the metric
          // TODO: this should be fixed in the backend
          // TODO: the 3 API calls are not needed, the backend should return the point in time options... i already talk with Cristian about this
          if (!isEditMode) {
            onUpdateWidget(
              {
                ...widget,
                point_in_time: widgetPointInTime
                  ? widgetPointInTime.value
                  : resultPitOptions[0]
                  ? resultPitOptions[0].value
                  : '',
              },
              isEditMode
            );
          } else if (!pointInTimeOptions.length) {
            onUpdateWidget(
              {
                ...widget,
                time_interval: timeIntervalOptions[0].value,
                time_period: timeValueOptions[0].value,
                point_in_time: widgetPointInTime
                  ? widgetPointInTime.value
                  : resultPitOptions[0]
                  ? resultPitOptions[0].value
                  : '',
              },
              isEditMode
            );
          } else {
            onUpdateWidget(
              {
                ...widget,
                point_in_time: '',
              },
              isEditMode
            );
          }
          setPointInTimeOptions(resultPitOptions);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load related point in time options: ${error}`);
        },
        signal: signalAbort.signal,
      });

      return () => {
        signalAbort.abort();
      };
    }
  }, [widget.time_interval, widget.time_period]);

  const groupByColumns: BIPivotDescriptor[] = useMemo(
    () =>
      pivotColumns
        .map((pivot) => {
          if (pivot.table_name) {
            pivot.table_icon =
              tableAliasesConfigs[pivot.table_name] ?? pivot.table_name;
          }
          return pivot;
        })
        .sort(
          (a, b) =>
            (a.table_name?.localeCompare(b.table_name ?? '') ?? 0) ||
            a.label.localeCompare(b.label)
        ),
    [pivotColumns, tableAliasesConfigs]
  );

  const dropDownCalendarOptions = useMemo(
    () =>
      timeValueOptions.map((option) => ({
        ...option,
        checked: option?.value === widget?.time_period,
      })) ?? [],
    [timeValueOptions, widget?.time_period]
  );

  const selectedTimeInterval = useMemo(
    () =>
      timeIntervalOptions.find((e) => e.value === widget.time_interval)?.text ??
      '',
    [timeIntervalOptions, widget.time_interval]
  );

  const selectedTimeValue = useMemo(
    () =>
      timeValueOptions.find((e) => e.value === widget.time_period)?.label ?? '',
    [timeValueOptions, widget.time_period]
  );

  const handleTimeIntervalChange = (values: string[]): void => {
    if (values[0] === widget.time_interval) {
      return;
    }
    onUpdateWidget({
      ...widget,
      time_interval: values[0],
      point_in_time: '',
    });
  };

  const handleTimePeriodChange = (selected: Filters.PersistValue[]): void => {
    const selectedValue = selected[0].id;
    onUpdateWidget({
      ...widget,
      time_period: selectedValue,
    });

    if (!timeValueOptions.find((option) => option?.value === selectedValue)) {
      timeValueOptions.push({
        label: selectedValue.split(',').join(' / '),
        value: selectedValue,
      });
    }
  };

  const handlePointInTimeChange = (values: string[]): void => {
    onUpdateWidget({
      ...widget,
      point_in_time: values[0],
    });
  };

  const handlePivotChange = (values: string[]): void => {
    const column = groupByColumns.find((column) => column.name === values[0]);
    const isTableVisualization = metricToChartType.some(
      (el) => el.chartType === VisualizationType.Table
    );
    const isStackedColumnVisualization = metricToChartType.some(
      (el) => el.chartType === VisualizationType.ColumnStacked
    );
    const isStackedBarVisualization = metricToChartType.some(
      (el) => el.chartType === VisualizationType.BarStacked
    );

    const hasPivot = !!column;
    const addingPivot = hasPivot && widget.group_by?.length === 0;
    const removingPivot = !hasPivot && !!widget?.group_by?.length;

    const chartType = isTableVisualization
      ? VisualizationType.Table
      : hasPivot && isStackedColumnVisualization
      ? VisualizationType.ColumnStacked
      : hasPivot && isStackedBarVisualization
      ? VisualizationType.BarStacked
      : addingPivot
      ? VisualizationType.ColumnStacked
      : VisualizationType.Table;

    const mtc = metricToChartType.map((el) => ({
      ...el,
      chartType: chartType,
    }));

    onUpdateWidget(
      {
        ...widget,
        group_by: hasPivot && column ? [column] : [],
        properties: {
          ...widget.properties,
          metricToChartType: mtc,
        },
        chart_type: chartType,
      },
      !addingPivot && !removingPivot
    );
  };

  const usedFormulaMetricIds = useMemo(() => {
    return (widget.metric_list as BIMetricUnion[])
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) =>
        parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
      );
  }, [JSON.stringify(widget.metric_list)]);

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);
  const tables = getWidgetObjects(widget as BIWidget, usedFormulaMetricsMap);

  return (
    <>
      <ControlsContainer isSpaceBetween={false}>
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Calendar} color="var(--bu-gray-700)" />
        </FilterBarElement>

        <BuSelect
          secondary
          fullWidth
          defaults={[
            widget?.time_interval
              ? widget.time_interval
              : timeIntervalOptions.length
              ? timeIntervalOptions[0].value
              : '',
          ]}
          options={timeIntervalOptions}
          onChange={handleTimeIntervalChange}
          inlineLabel="Time Interval"
          testingLabel="time-interval"
        />

        <DropdownCalendar
          forceDown
          align="left"
          className="white"
          config={{
            allowFuture: true,
            checkedAll: false,
            checkedTotal: 0,
            title: 'Time Period',
            type: 'date',
            withShowAll: true,
            isLocked: false,
            customEnd: true,
            customStart: true,
            values: dropDownCalendarOptions,
          }}
          key="Filter"
          name="Filter"
          onChange={handleTimePeriodChange}
          tab="revbi"
        />
      </ControlsContainer>

      <ControlsContainer isSpaceBetween={false}>
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Location} color="var(--bu-gray-700)" />
        </FilterBarElement>

        <BuSelect
          fullWidth
          secondary
          defaults={[
            widget?.point_in_time
              ? widget.point_in_time
              : pointInTimeOptions.length
              ? pointInTimeOptions[0].value
              : '',
          ]}
          options={pointInTimeOptions}
          onChange={handlePointInTimeChange}
          inlineLabel="Point-in-time"
          testingLabel="point-in-time"
        />
      </ControlsContainer>

      <PivotControls
        availablePivots={groupByColumns}
        hasFSMetric={false}
        groups={widget.group_by ?? []}
        isPieChart={false}
        isTargetMetricOnlyWidget={false}
        timeField={widget.time_field}
        onPivotChange={handlePivotChange}
        isHistorical
      />

      <TemplateFilters
        showControls
        templateFilters={widget.template_filters}
        widgetFilters={widget.widget_filters}
        dashboardFilters={widget.dashboard_filters ?? []}
        tables={tables}
        relativeDateFilterOptions={dateTemplateFilterOptions}
        timeInterval={selectedTimeInterval}
        timePeriod={selectedTimeValue}
        onChangeWidget={onUpdateWidget}
      />
    </>
  );
};
